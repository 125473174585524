<template>
  <mex-dialog
    :showDialog="showAddClinicUUIDToBlocklistDialog"
    dialogTitle="Add Clinic UUID to blocklist"
    width="50%"
  >
    <template v-slot:dialog-content>
      <v-overlay v-if="clinicUUIDAdding">
        <mex-sperm-spinner spinnerText="Adding Clinic UUID" />
      </v-overlay>
      <v-row justify="center">
        <v-col cols="auto">
          <mex-p
            fontSize="h6"
            alignment="center"
            content="If a clinic UUID is added to the block list, all incoming requests that provide this clinic UUID in the credentials are blocked. Only valid UUID of type 4 can be added to the blocklist. Please give a meaningful description why you want to block this clinic UUID."
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-text-field
            v-model="clinicUUID"
            hide-details
            label="Clinic UUID "
            outlined
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-text-field
            v-model="description"
            hide-details
            label="Description"
            outlined
          />
        </v-col>
      </v-row>
    </template>
    <template slot="dialog-actions">
      <v-row justify="center">
        <v-col cols="auto">
          <mex-btn content="Add Clinic UUID " icon="mdi-plus" @click="addClinicUUID" />
        </v-col>
        <v-col cols="auto">
          <mex-btn
            content="Discard"
            icon="mdi-delete"
            @click="discradAddClinicUUIDToBlocklistDialog"
          />
        </v-col>
      </v-row>
    </template>
  </mex-dialog>
</template>

<script>
import MexSpermSpinner from "../MedITEX_Vue_Components/MexComponents/MexSpermSpinner.vue";
import ClinicUUIDBlocklistService from "../../services/clinicUUIDBlocklist.service";

export default {
  name: "AddClinicUuidToBlocklistDialog",
  components: { MexSpermSpinner },
  props: {
    showAddClinicUUIDToBlocklistDialog: {
      type: Boolean,
      default: false
    },
    clinicUUIDPreSelection: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // data
      description: "",
      clinicUUID: "",
      // visualization
      clinicUUIDAdding: false
    };
  },
  watch: {
    clinicUUIDPreSelection(newValue) {
      if (newValue !== "") {
        this.clinicUUID = newValue;
      }
    }
  },
  mounted() {
    if (this.clinicUUIDPreSelection) {
      this.clinicUUID = this.clinicUUIDPreSelection;
    }
  },
  methods: {
    discradAddClinicUUIDToBlocklistDialog() {
      this.clinicUUIDPreSelection = "";
      this.clinicUUID = "";
      this.description = "";
      this.$emit("reject");
    },
    addClinicUUID() {
      this.clinicUUIDAdding = true;
      ClinicUUIDBlocklistService.addClinicUUIDToBlocklist(this.clinicUUID, this.description)
        .then(() => {
          this.$emit("confirm");
          this.$toast.success(this.clinicUUID + "  successfully added to blocklist");
          this.clinicUUIDAdding = false;
          this.clinicUUID = "";
          this.description = "";
          this.clinicUUIDPreSelection = "";
        })
        .catch(err => {
          this.clinicUUIDAdding = false;
          this.$toast.error("Error while adding \"" + this.clinicUUID + "\" to blocklist: " + err.response.data);
        });
    }
  }
};
</script>

<style></style>
