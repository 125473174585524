import httpClient from "../httpClient/httpClient";

const ClinicUUIDBlocklistService = {
  getClinicUUIDBlocklist() {
    return httpClient.get("api/get-clinic-uuid-blocklist", {});
  },
  addClinicUUIDToBlocklist(clinicUUID, description) {
    return httpClient.post("api/add-clinic-uuid-to-blocklist", { clinicUUID, description });
  },
  deleteClinicUUIDFromBlocklist(clinicUUIDBlocklistID) {
    return httpClient.post("api/delete-clinic-uuid-from-blocklist", { clinicUUIDBlocklistID });
  }
};

export default ClinicUUIDBlocklistService;
