<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Clinic UUID Blocklist" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
      <mex-data-table
        dense
        :items-per-page="getTablePagination.defaultRowsPerPage"
        :data="clinicUUIDBlocklist"
        :headers="clinicUUIDBlocklistHeaders"
        tableClass="foreground"
        :footer-props="{
          'items-per-page-options': getTablePagination.rowsPerPage,
        }"
      >
        <template v-slot:item.remove="{item}">
          <mex-btn iconOnly icon="mdi-delete" @click="removeEntry(item)" />
        </template>
      </mex-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <mex-btn content="Add Clinic UUID" icon="mdi-plus" @click="showAddClinicUUIDToBlocklistDialog = true" />
      </v-col>
    </v-row>
    <add-clinic-uuid-to-blocklist-dialog
      :showAddClinicUUIDToBlocklistDialog="showAddClinicUUIDToBlocklistDialog"
      :clinicUUIDPreSelection="clinicUUIDPreSelection"
      @reject="closeAddClinicUUIDToBlocklistDialog"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import requiredPermissions from "../../requiredPermissions";
import AddClinicUuidToBlocklistDialog from "../../components/LicSrvComponents/AddClinicUUIDToBlocklistDialog";
import ClinicUUIDBlocklistService from "../../services/clinicUUIDBlocklist.service";
import tablePagination from '../../config/tablePagination.config';

export default {
  name: "ClinicUUIDBlocklist",
  components: {
    AddClinicUuidToBlocklistDialog,
  },
  computed: {
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      // data
      clinicUUIDBlocklist: [],
      clinicUUIDBlocklistHeaders: [
        {
          text: "Clinic UUID",
          align: "start",
          sortable: true,
          value: "clinicUUID"
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description"
        },
        {
          text: "created at",
          align: "start",
          sortable: true,
          value: "createdAt"
        },
        {
          text: "",
          align: "start",
          sortable: true,
          value: "remove"
        }
      ],
      hasPermission: false,
      showAddClinicUUIDToBlocklistDialog: false,
      clinicUUIDPreSelection: "",
    };
  },
  created() {
    // REPLACE WITH REAL PERMISSIONS
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.systemSettings, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchClinicUUIDBlocklist();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  methods: {
    fetchClinicUUIDBlocklist() {
      ClinicUUIDBlocklistService.getClinicUUIDBlocklist()
        .then((response) => {
          this.clinicUUIDBlocklist = response.data;
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    removeEntry(item) {
      ClinicUUIDBlocklistService.deleteClinicUUIDFromBlocklist(item.ClinicUUIDBlocklistID)
        .then(() => {
          this.fetchClinicUUIDBlocklist();
          this.$toast.success("Successfully removed " + item.clinicUUID  + " from Blocklist");
        })
        .catch((err) => {
          this.$toast.error(err.response.data)
        })
    },
    closeAddClinicUUIDToBlocklistDialog() {
      this.showAddClinicUUIDToBlocklistDialog = false;
      this.fetchClinicUUIDBlocklist();
    }
  }
};
</script>

<style>
.extendedClinicRow {
  background-color: var(--v-secondary-base);
}
</style>
